import React, { useState } from "react"

export const PricingContext = React.createContext({
  pricesAuthoringAndDelivery: {},
  pricesAuthoring: {},
  setPricesAuthoring: () => {},
  setPricesAuthoringAndDelivery: () => {},
  currencies: [],
  isBackActive: false,
  setIsBackActive: () => {},
  data: [],
})

const PricingProvider = ({ children, data }) => {
  const [pricesAuthoringAndDelivery, setPricesAuthoringAndDelivery] = useState(data?.tableDelivery.currencies[0] || {})
  const [pricesAuthoring, setPricesAuthoring] = useState(data?.tableAuthoring.currencies[0] || {})
  const [currencies, setCurrencies] = useState(data?.tableAuthoring.currencies || {})
  const [isBackActive, setIsBackActive] = useState(false)

  return (
    <PricingContext.Provider
      value={{
        pricesAuthoringAndDelivery,
        pricesAuthoring,
        setPricesAuthoringAndDelivery,
        setPricesAuthoring,
        currencies,
        isBackActive,
        setIsBackActive,
        data,
      }}
    >
      {children}
    </PricingContext.Provider>
  )
}

export default PricingProvider
