import React, { useContext } from "react"
import cx from "classnames"

import { PricingContext } from "../pricingProvider"
import PricingCard from "./pricingCard"

import styles from "./pricingCards.module.scss"

const sizes = ["small", "medium", "enterprise"]

const PricingCards = () => {
  const { isBackActive, data, pricesAuthoringAndDelivery, pricesAuthoring } = useContext(PricingContext)

  return (
    <div className={styles.wrapper}>
      <div className={cx("grid", styles.rows)}>
        {sizes.map((el, index) => (
          <div
            key={el}
            className={cx(
              "gridItem small-12 medium-4",
              styles.item,
              isBackActive ? styles.activeBack : "",
              data.authoring[index].popular ? styles.gridItemPopular : ""
            )}
          >
            <div className={cx(styles.front, data.authoring[index].popular ? styles.popular : "")}>
              <PricingCard item={data?.authoring[index]} size={el} prices={pricesAuthoring} />
            </div>
            <div className={cx(styles.back, data.authoringAndDelivery[index].popular ? styles.popular : "")}>
              <PricingCard item={data?.authoringAndDelivery[index]} size={el} prices={pricesAuthoringAndDelivery} />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PricingCards
