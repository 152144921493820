import React, { useContext, useEffect, useState } from "react"

import FormattedLink from "../../formattedLink/formattedLink"
// import PricingCurrency from "../pricingCurrency/pricingCurrency"
import { PricingContext } from "../pricingProvider"
import Check from "../../../../assets/svg/check.svg"

import styles from "./pricingTable.module.scss"

const PricingTable = () => {
  const { pricesAuthoring, pricesAuthoringAndDelivery, isBackActive, data } = useContext(PricingContext)
  const [tableData, setTableData] = useState([])
  const [links, setLinks] = useState([])
  const [prices, setPrices] = useState(pricesAuthoring)

  useEffect(() => {
    if (isBackActive) {
      setTableData(data.tableDelivery.table)
      setLinks(data.authoringAndDelivery)
      setPrices(pricesAuthoringAndDelivery)
    } else {
      setTableData(data.tableAuthoring.table)
      setLinks(data.authoring)
      setPrices(pricesAuthoring)
    }
  }, [isBackActive, data, pricesAuthoring, pricesAuthoringAndDelivery])

  return (
    <div className={styles.wrapper}>
      <h2 className={styles.sectionTitle}>{data.tableSectionTitle}</h2>
      <div>
        <table className={styles.table}>
          {/* HEADER */}
          <thead>
            <tr>
              <th className={styles.visuallyHidden}>Category</th>
              <th>Small</th>
              <th>Medium</th>
              <th>Enterprise</th>
            </tr>
          </thead>
          {/* BODY */}
          <tbody>
            {/* PRICES */}
            <tr>
              <td className={styles.price}>
                <span>Price per year</span>
                {/* <PricingCurrency /> */}
              </td>
              <td>
                <span>
                  {prices.currencySymbol}
                  {prices.small}
                </span>
              </td>
              <td>
                <span>
                  {prices.currencySymbol}
                  {prices.medium}
                </span>
              </td>
              <td>
                <span>{prices.enterprise}</span>
              </td>
            </tr>
            {/* CONTENT */}
            {tableData?.map((row, index) => (
              <tr key={`${row.title}-${index}`}>
                <td className={styles.bigColumn}>
                  <p className={styles.title}>{row.title} </p>
                  <p className={styles.description}>{row.description}</p>
                </td>
                {row.__typename === "SanityPricingTableItemCheckboxes" ? (
                  <>
                    <td>
                      {row.small === "check" ? (
                        <span className={styles.check}>
                          <Check />
                        </span>
                      ) : (
                        <span className={styles.cross} />
                      )}
                    </td>
                    <td>
                      {row.medium === "check" ? (
                        <span className={styles.check}>
                          <Check />
                        </span>
                      ) : (
                        <span className={styles.cross} />
                      )}
                    </td>
                    <td>
                      {row.enterprise === "check" ? (
                        <span className={styles.check}>
                          <Check />
                        </span>
                      ) : (
                        <span className={styles.cross} />
                      )}
                    </td>
                  </>
                ) : (
                  <>
                    <td>
                      <span>{row.small}</span>
                    </td>
                    <td>
                      <span>{row.medium}</span>
                    </td>
                    <td>
                      <span>{row.enterprise}</span>
                    </td>
                  </>
                )}
              </tr>
            ))}
            {/* LINKS */}
            <tr className={styles.buttons}>
              <td></td>
              <>
                {links?.map((item, index) => (
                  <td key={`${item.linkLabel}-${index}`}>
                    <span>
                      <FormattedLink type="link-pink" link={item.url}>
                        {item.linkLabel}
                      </FormattedLink>
                    </span>
                  </td>
                ))}
              </>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default PricingTable
