import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo/seo"
import Layout from "../components/layout/layout"
import Cta from "../components/cta/cta"
import Hero from "../components/hero/heroSimple/heroSimple"
import PricingCards from "../components/pricing/pricingCards/pricingCards"
import Accordion from "../components/accordion/accordion"
import PricingTable from "../components/pricing/pricingTable/pricingTable"
import PricingButtons from "../components/pricing/pricingButtons/pricingButtons"
import PricingProvider from "../components/pricing/pricingProvider"

const Pricing = ({ data: { sanityPricing: page } }) => {
  const { content, seo } = page.tabs
  return (
    <Layout>
      <Seo isBlogPost={false} {...seo} title={content.title} />
      <Hero title={content.hero} subtitle={content.subtitle} />
      <PricingProvider data={content}>
        <PricingButtons />
        <PricingCards />
        <PricingTable />
      </PricingProvider>
      <Accordion title={content.faq.title} sections={content.faq.sections} text={content.faq.text} />
      <Cta {...content.cta} background="blue" />
    </Layout>
  )
}

export const query = graphql`
  query {
    sanityPricing {
      tabs {
        seo {
          canonical
          description
          metaTitle
          noIndex
          openGraphImage {
            asset {
              url
            }
          }
          removeSitemap
        }
        content {
          hero
          title
          subtitle
          authoring {
            call
            linkLabel
            popular
            text
            title
            price
            url
          }
          authoringAndDelivery {
            call
            linkLabel
            popular
            text
            title
            price
            url
          }
          tableSectionTitle
          tableAuthoring {
            currencies {
              currency
              currencySymbol
              enterprise
              medium
              small
            }
            table {
              ... on SanityPricingTableItem {
                description
                enterprise
                medium
                title
                small
              }
              ... on SanityPricingTableItemCheckboxes {
                title
                small
                medium
                enterprise
                description
              }
            }
          }
          tableDelivery {
            currencies {
              currency
              currencySymbol
              enterprise
              medium
              small
            }
            table {
              ... on SanityPricingTableItem {
                description
                enterprise
                medium
                title
                small
              }
              ... on SanityPricingTableItemCheckboxes {
                title
                small
                medium
                enterprise
                description
              }
            }
          }
          faq {
            title
            sections {
              _key
              title
              _rawText
            }
            text: _rawText
          }
          cta {
            ...ctaFragment
          }
        }
      }
    }
  }
`
export default Pricing
