import React from "react"
import PropTypes from "prop-types"

import FormattedLink from "../../formattedLink/formattedLink"

import styles from "./pricingCards.module.scss"

const PricingCard = ({ item, prices, size }) => (
  <div data-testid="pricingCard">
    {item.popular ? <span className={styles.popularTag}>POPULAR</span> : null}
    <div className={styles.inner}>
      <p className={styles.title}>{item.title}</p>
      {item.call ? (
        <p className={styles.call}>Call to discuss</p>
      ) : (
        <p>
          <span className={styles.price}>
            {prices.currencySymbol}
            {prices[size]}
          </span>
          / year
        </p>
      )}
      <p className={styles.text}>{item.text}</p>
      <p>
        <FormattedLink type="link-pink" link={item.url}>
          {item.linkLabel}
        </FormattedLink>
      </p>
    </div>
  </div>
)

export default PricingCard

PricingCard.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string.isRequired,
    call: PropTypes.bool,
    price: PropTypes.string,
    text: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    linkLabel: PropTypes.string.isRequired,
  }),
}
