import React, { useContext } from "react"

import { PricingContext } from "../pricingProvider"
// import PricingCurrency from "../pricingCurrency/pricingCurrency"

import styles from "./pricingButtons.module.scss"

const PricingButtons = () => {
  const { isBackActive, setIsBackActive } = useContext(PricingContext)

  return (
    <>
      <div className={styles.buttons}>
        <button
          className={isBackActive ? `btn-primary ${styles.inactive}` : "btn-primary"}
          onClick={() => setIsBackActive(false)}
        >
          Authoring
        </button>
        <button
          className={isBackActive ? "btn-primary" : `btn-primary ${styles.inactive}`}
          onClick={() => setIsBackActive(true)}
        >
          Authoring + Delivery
        </button>
      </div>
      {/* <div className={styles.currenciesWrapper}>
        <PricingCurrency />
      </div> */}
    </>
  )
}
export default PricingButtons
